<template>
	<el-form v-loading="isLoading">
		<el-card class="box-card">
			<div slot="header">
				<div class="title-container">
					<div style="font-weight: bold">Filter Preference</div>
					<el-button type="primary" @click="save">Save</el-button>
				</div>
			</div>

			<el-form label-width="80px" label-position="top">
				<el-form-item label="Management">
					<el-select v-model="preferManagementRange">
						<el-option
							v-for="option in managementOptions"
							:key="option.value"
							:label="option.text"
							:value="option.value"
						/>
					</el-select>
				</el-form-item>

				<el-form-item label="Fulfillment">
					<el-select v-model="preferFulfillmentRange">
						<el-option
							v-for="option in fulfillmentOptions"
							:key="option.value"
							:label="option.text"
							:value="option.value"
						/>
					</el-select>
				</el-form-item>

				<el-form-item label="K Parcel">
					<el-select v-model="preferKParcelRange">
						<el-option
							v-for="option in fulfillmentOptions"
							:key="option.value"
							:label="option.text"
							:value="option.value"
						/>
					</el-select>
				</el-form-item>
			</el-form>
		</el-card>
	</el-form>
</template>

<script>
	import {
		computed,
		onMounted,
		ref,
		getCurrentInstance,
	} from "@vue/composition-api";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
	import {
		RANGE_CURRENT_MONTH,
		RANGE_YEAR_TO_DATE,
		RANGE_FULL_YEAR,
		RANGE_RANGE_YEARS,
	} from "@configs/filter";
	import {
		getFilterPreference,
		getProfile,
		setFilterPreference,
	} from "@/utils/account-localstorage";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
	import store from "@/store";

	export default {
		setup(props, ctx) {
			const { $toast } = getCurrentInstance().proxy;

			const isLoading = ref(false);
			const loadFail = ref(false);

			const preferManagementRange = ref(
				getFilterPreference().preferManagementRange
			);
			const preferFulfillmentRange = ref(
				getFilterPreference().preferFulfillmentRange
			);
			const preferKParcelRange = ref(
				getFilterPreference().preferKParcelRange
			);

			const managementOptions = [
				{ value: RANGE_YEAR_TO_DATE, text: "Year to Date" },
				{ value: RANGE_FULL_YEAR, text: "Full Year" },
				{ value: RANGE_RANGE_YEARS, text: "Last 5 years" },
			];

			const fulfillmentOptions = [
				{ value: RANGE_CURRENT_MONTH, text: "Current Month" },
				{ value: RANGE_YEAR_TO_DATE, text: "Year to Date" },
				{ value: RANGE_FULL_YEAR, text: "Full Year" },
				{ value: RANGE_RANGE_YEARS, text: "Last 5 years" },
			];

			const save = async () => {
				isLoading.value = true;
				loadFail.value = false;

				try {
					let filterPreference = {
						preferManagementRange: preferManagementRange.value,
						preferFulfillmentRange: preferFulfillmentRange.value,
						preferKParcelRange: preferKParcelRange.value,
					};

					const response = await fetcher(
						apis.updateFilterPreference,
						FetchMethod.POST,
						{
							userId: getProfile().id,
							...filterPreference,
						}
					);
					setFilterPreference(filterPreference);

					$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							title: "Save Success",
							icon: "CheckIcon",
							variant: "success",
						},
					});
				} catch (e) {
					loadFail.value = true;

					store.commit("app/SHOW_ERROR_DIALOG", {
						isOpen: true,
						message: e,
					});
				} finally {
					isLoading.value = false;
				}
			};

			return {
				preferManagementRange,
				preferFulfillmentRange,
				preferKParcelRange,

				managementOptions,
				fulfillmentOptions,
				save,

				isLoading,
				loadFail,
			};
		},
	};
</script>

<style scoped>
	.title-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
</style>